var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticStyle: { padding: "0px 0px 0px" } },
    [
      _c("div", { attrs: { id: "ecac-messages-background" } }),
      _c("HeaderMessage"),
      _c(
        "v-card",
        {
          staticStyle: {
            padding: "0px 0px 0px",
            left: "-15px",
            "margin-top": "32px",
            "background-color": "#f7f7fd !important",
          },
          attrs: { id: "cardMessage" },
        },
        [
          _c(
            "v-card-title",
            { staticStyle: { "background-color": "#f7f7fd" } },
            [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tabs-slider", { attrs: { color: "#00FF99" } }),
                  _c(
                    "v-tab",
                    {
                      attrs: { id: "idTabUrgent", href: "#urgente" },
                      on: { click: _vm.changeTabDebounce },
                    },
                    [
                      _c("div", { staticClass: "tab-text" }, [
                        _vm._v("Urgente (" + _vm._s(_vm.lengthUrgent) + ")"),
                      ]),
                    ]
                  ),
                  _c(
                    "v-tab",
                    {
                      attrs: { id: "idTabNotReaded", href: "#nao-lido" },
                      on: { click: _vm.changeTabDebounce },
                    },
                    [
                      _c("div", { staticClass: "tab-text" }, [
                        _vm._v("Não Lido (" + _vm._s(_vm.lengthNotRead) + ")"),
                      ]),
                    ]
                  ),
                  _c(
                    "v-tab",
                    {
                      attrs: { id: "idTabReaded", href: "#lido" },
                      on: { click: _vm.changeTabDebounce },
                    },
                    [
                      _c("div", { staticClass: "tab-text" }, [
                        _vm._v("Lido (" + _vm._s(_vm.lengthRead) + ")"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              staticStyle: { "background-color": "#f7f7fd !important" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab-item",
                { attrs: { value: "urgente" } },
                [
                  _c("TableMessage", {
                    attrs: {
                      read: _vm.paramFilter.read,
                      urgent: _vm.paramFilter.urgent,
                    },
                    on: { refresh: _vm.refreshUrgent },
                  }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "nao-lido" } },
                [
                  _c("TableMessage", {
                    attrs: {
                      read: _vm.paramFilter.read,
                      urgent: _vm.paramFilter.urgent,
                    },
                    on: { refresh: _vm.refreshNotRead },
                  }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "lido" } },
                [
                  _c("TableMessage", {
                    attrs: {
                      read: _vm.paramFilter.read,
                      urgent: _vm.paramFilter.urgent,
                    },
                    on: { refresh: _vm.refreshRead },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ModalCommentMessage", {
        ref: "modalCommentMessage",
        attrs: { item: _vm.messageComment },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }