import { axiosEcacMessages } from '@/services/config.js';
import router from '@/router/index.js';

let configforXLS = {
  responseType: 'arraybuffer',

  contentType: 'application/force-download',

  'Content-Disposition': 'attachment',
};

axiosEcacMessages.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    
    if (error.response.status === 401) {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
      localStorage.setItem('Error', 'Token vencido, por favor fazer login novamente.');
      router.push('/');
    }
    return Promise.reject(error);
  },
);
export default {
  messages: {
    count: () => {
      return axiosEcacMessages.get('caixa-postal/message/count');
    },
    getAll: () => {
      return axiosEcacMessages.post('caixa-postal/message/search', {});
    },
    getAllFilter: (pagination, params) => {
      return axiosEcacMessages.post(
        'caixa-postal/message/search?page=' + pagination.page + '&size=' + pagination.size,
        params,
      );
    },
    getDetails: (item) => {
      return axiosEcacMessages.get(`caixa-postal/message/details/${item.cnpj}/${item.isn}`);
    },

    update: (data) => {
      return axiosEcacMessages.put('caixa-postal/message/condition', data);
    },

    downloadReport: (data, pageable) => {
      return axiosEcacMessages.post('caixa-postal/message/export?page=' + pageable.page + '&size=' + pageable.size, data, configforXLS);
    },
  },
  comment: {
    save: (params) => {
      return axiosEcacMessages.post('caixa-postal/message/comment', params);
    },
    get: (item, params) => {
      return axiosEcacMessages.get(`caixa-postal/message/comment/${item.isn}/${item.cnpj}?` + params);
    },
    downloadReport: (item) => {
      return axiosEcacMessages.get(`caixa-postal/message/comment/export/${item.isn}/${item.cnpj}`, configforXLS);
    },
  },
  historic: {
    get: (item) => {
      return axiosEcacMessages.get(`caixa-postal/message/history/${item.isn}/${item.cnpj}`);
    },

    downloadReport: (item) => {
      return axiosEcacMessages.get(`caixa-postal/message/history/export/${item.isn}/${item.cnpj}`, configforXLS);
    },
  },
  processes: {
    get: () => {
      return axiosEcacMessages.get(`caixa-postal/message/processes`);
    },

    downloadReport: () => {
      return axiosEcacMessages.get(`caixa-postal/message/processes/export`, configforXLS);
    },
  },
  rule: {
    getAllFilter: (pagination, filter) => {
      return axiosEcacMessages.post(
        'caixa-postal/rule/search?page=' + pagination.page + '&size=' + pagination.size,
        {
          term: filter.term,
          emails: filter.emails,
          cnpjs: filter.cnpjs,
          authors: filter.authors,
        },
      );
    },
    downloadReport: (pagination, filter) => {
      return axiosEcacMessages.post(
        'caixa-postal/rule/export?page=' + pagination.page + '&size=' + pagination.size,
        {
          emails: filter.emails,
          cnpjs: filter.cnpjs,
          authors: filter.authors,
        },
        configforXLS,
      );
    },
    save: (rule) => {
      return axiosEcacMessages.post('caixa-postal/rule/', {
        emails: rule.emails,
        cnpj: rule.cnpj,
        term: rule.term,
        id: rule.id,
      });
    },
    delete: (id) => {
      return axiosEcacMessages.delete('caixa-postal/rule/' + id);
    },
  },
};
