<template>
  <v-main style="padding: 0px 0px 0px">
    <div id="ecac-messages-background"></div>

    <HeaderMessage />

    <v-card
      id="cardMessage"
      style="padding: 0px 0px 0px; left: -15px; margin-top: 32px; background-color: #f7f7fd !important"
    >
      <v-card-title style="background-color: #f7f7fd">
        <v-tabs v-model="tab">
          <v-tabs-slider color="#00FF99"></v-tabs-slider>
          <v-tab id="idTabUrgent" href="#urgente" @click="changeTabDebounce"
            ><div class="tab-text">Urgente ({{ lengthUrgent }})</div></v-tab
          >
          <v-tab id="idTabNotReaded" href="#nao-lido" @click="changeTabDebounce"
            ><div class="tab-text">Não Lido ({{ lengthNotRead }})</div></v-tab
          >
          <v-tab id="idTabReaded" href="#lido" @click="changeTabDebounce"
            ><div class="tab-text">Lido ({{ lengthRead }})</div></v-tab
          >
        </v-tabs>
      </v-card-title>

      <v-tabs-items v-model="tab" style="background-color: #f7f7fd !important">
        <v-tab-item :value="'urgente'">
          <TableMessage :read="paramFilter.read" :urgent="paramFilter.urgent" @refresh="refreshUrgent" />
        </v-tab-item>
        <v-tab-item :value="'nao-lido'">
          <TableMessage :read="paramFilter.read" :urgent="paramFilter.urgent" @refresh="refreshNotRead" />
        </v-tab-item>
        <v-tab-item :value="'lido'">
          <TableMessage :read="paramFilter.read" :urgent="paramFilter.urgent" @refresh="refreshRead" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <ModalCommentMessage ref="modalCommentMessage" :item="messageComment" />
  </v-main>
</template>
<script>
import IntegraContadorService from '@/services/integraContadorService.js';
import debounce from 'lodash/debounce';

export default {
  name: 'Mensagens',

  components: {
    HeaderMessage: () => import('./components/HeaderMessage.vue'),
    TableMessage: () => import('./components/TableMessage.vue'),
    ModalCommentMessage: () => import('./components/ModalCommentMessage.vue'),
  },

  data() {
    return {
      ready: false,
      tab: null,
      lengthUrgent: 0,
      lengthRead: 0,
      lengthNotRead: 0,
      datesFormat: [],
      countUrgente: 99,
      countLido: 92,
      countNaoLido: 7,
      situacao: ['Resolvido', 'Verficado', 'Em andamento'],
      relevancia: ['Relevante', 'Não relevante'],
      filterDateColor: 'primary',
      filter: {
        dates: [],
        datesFormat: [
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10),
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10),
        ],

        prazo: null,
        companiesSelect: [],
        situacaoSelect: [],
        relevanciaSelect: [],
        term: '',
      },

      pickerMessage: 'Preencha um período de até 60 dias',
      messageItem: {},
      messageComment: {},
      paramFilter: {
        read: null,
        urgent: null,
      },

      changeTabDebounce: debounce((event) => this.changeTab(event), 500),
    };
  },

  computed: {
    computeRange: {
      get() {
        if (this.filter.dates == undefined || this.filter.dates.length == 0) {
          return 'Período de recebimento';
        }
        let from = this.filter.dates[0];
        let to = this.filter.dates[1];
        let fromMilis = this.getMillisecondsDate(from);
        let toMilis = this.getMillisecondsDate(to);

        if (this.isInvalidDates(fromMilis, toMilis)) {
          if (this.isRevertedDates(fromMilis, toMilis)) {
            this.pickerMessage = 'Preencha primeiro a data mais antiga';
          }
          this.disabledInputs = true;
          this.filterDateColor = '#962d33';
        } else {
          this.pickerMessage = 'Preencha um período de até 60 dias';
          this.disabledInputs = false;
          this.filterDateColor = 'primary';
        }
        this.filter.datesFormat = [this.formatDate(from), this.formatDate(to)];
        return this.filter.datesFormat.join(' a ');
      },
    },

    computePrazo: {
      get() {
        if (this.filter.prazo == undefined || this.filter.prazo.length == 0) {
          return 'Vencimento';
        }
        return this.formatDate(this.filter.prazo);
      },
    },
  },

  watch: {
    tab: 'getParams',
  },

  created() {
    this.init();
  },

  methods: {
    async init() {
      let counts = await IntegraContadorService.messages.count();
      this.lengthUrgent = counts.data.urgent;
      this.lengthNotRead = counts.data.not_read;
      this.lengthRead = counts.data.read;
      this.ready = true;
    },

    getParams() {
      this.paramFilter = {};
      switch (this.tab) {
        case 'urgente':
          this.paramFilter.urgent = true;
          break;
        case 'nao-lido':
          this.paramFilter.read = false;
          break;
        case 'lido':
          this.paramFilter.read = true;
          break;
        default:
          console.log('Erro ao buscar menssagens');
          break;
      }
    },

    changeTab() {
      if (this.ready) {
        this.getParams();
        this.$root.$emit('updateTable');
        this.ready = false;
      }
    },

    refreshUrgent(param) {
      this.lengthUrgent = param;
      this.init();
    },

    refreshRead(param) {
      this.lengthRead = param;
      this.init();
    },

    refreshNotRead(param) {
      this.lengthNotRead = param;
      this.init();
    },

    commentsModal(item) {
      this.messageComment = {
        messageIsn: item.isn,
        messageCnpj: item.cnpj,
        comment: this.comentario,
      };

      this.$refs.modalCommentMessage.$emit('open');
    },

    concatDate(arrayDate) {
      return [this.formatDate(arrayDate[0]), this.formatDate(arrayDate[1])].join(' a ');
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    clickChip(value, paramArr) {
      paramArr.splice(value, 1);
    },

    isInvalidDates(from, to) {
      let sixtyDays = 60;
      return !from || !to || this.isRevertedDates(from, to) || (to - from) / 1000 / 60 / 60 / 24 > sixtyDays;
    },

    isRevertedDates(from, to) {
      return to < from;
    },

    async exportar() {
      let pageable = {
        page: 0,
        size: Number.MAX_SAFE_INTEGER,
      };
      let response = await IntegraContadorService.messages.downloadReport(this.paramFilter, pageable);
      if (response) this.loading3 = false;
      let blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let date = new Date();

      link.download = `Mensagens do Ecac - Extraído em ${date.toLocaleDateString('pt-br')}.xlsx`;
      link.click();
      this.showToast();
    },
  },
};
</script>
<style scoped>
.primary-default {
  height: 48px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid primary;
  background-color: var(--v-primary-base);
  color: #fff;
}
.secundary-default {
  height: 48px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  border: 1px solid primary;
  background-color: #fff !important;
  color: var(--v-primary-base);
}
.btn-exportar-enabled {
  height: 48px !important;
  top: 0px;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid #fff;
  background-color: #fff !important;
  color: var(--v-primary-base);
}

.btn-exportar-disabled {
  height: 48px !important;
  top: 0px;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  background-color: #e7e7fa !important;
  color: #8d8d99 !important;
}
.thead {
  background-color: #e7e7fa;
  color: var(--v-primary-base);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  line-height: 18.62px;
  letter-spacing: 1%;
  height: 51px;
}
.thead td th {
  flex-direction: row;
  align-items: flex-start;
}
.tbody {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1%;
  color: #575767;
}
.tbody tr td {
  padding: 16px;
}
.empty {
  text-align: center;
  padding: 24px 0px;
  gap: 10px;
  height: 72px;
}
.tbody tr td#action {
  padding: 0px;
}
.tbody tr td div strong {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 133%;
  color: #2a1c4f;
  letter-spacing: 0.01em;
}
#date-hint-div {
  font-size: 12px;
  margin-top: -18px;
}

#date-hint-div p {
  bottom: 3px;
}

#date-hint-div.v-messages__message {
  color: #962d33;
}
.prazo2dias {
  color: #610006;
}
.prazo20dias {
  color: #006144;
}
.v-card__title {
  padding: 0px !important;
}
.v-tabs-slider-wrapper {
  background-color: #e513b8 !important;
  color: #e513b8 !important;
  border: 2px solid #006144 !important;
}
.v-tab {
  padding: 8px !important;
  text-transform: none;
}

.v-tab:hover {
  background-color: #ffffff !important;
}
.tab-text:hover {
  background-color: #e3ffee !important;
}
.v-tab--active {
  padding: 8px !important;
  text-transform: none;
}
.v-tab .tab-text {
  padding: 4px 32px;
  margin: 0px;
  border-radius: 4px;
}
.v-tab--active .tab-text {
  background-color: #e3ffee !important;
  padding: 4px 32px;
  margin: 0px;
}

#ecac-messages-background {
  background-color: #f7f7fd;
  position: fixed;
  width: 300%;
  height: 300%;
  margin-left: -90%;
  margin-top: -9%;
}

#cardMessage {
  border: 1px solid #e7e7fa !important;
}

::v-deep .v-slide-group__content.v-tabs-bar__content {
  background: #f7f7fd;
}
</style>
